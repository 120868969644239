import * as SurveyCore from "survey-core";
import i18next from "../localization/i18n";
import Preference from "../data/Preference";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import { SupportedCRM } from "../enums/SupportedCRM";
import GetLeadContactAttributeOptions from "../api/GetLeadContactAttributeOptions";
import { EntityType } from "../enums/EntityType";
import SetMapperAttributes from "../helpers/SetMapperAttributes";

type Option = {
  value: string;
  text: string;
};
export const LeadOptionsAttributes: { [option: string]: Option[] } = {};
export const ContactOptionsAttributes: { [option: string]: Option[] } = {};
export function SetContactChoices(value: string, options: Option[]) {
  if (!ContactOptionsAttributes[value]) {
    ContactOptionsAttributes[value] = options;
  }
}

export function SetLeadChoices(value: string, options: Option[]) {
  if (!LeadOptionsAttributes[value]) {
    LeadOptionsAttributes[value] = options;
  }
}

export default function ItemValueCommon(stateParams: FormStateParams | SubscriptionsStateParams) {
  function updateLeadContactOptionsChoices(
    value: any,
    entityType: EntityType,
    arrayToSet: { [option: string]: Option[] },
    callback: any,
  ) {
    if (!value) {
      callback([{}]);
    } else if (arrayToSet[value]) {
      callback(arrayToSet[value]);
    } else {
      GetLeadContactAttributeOptions(stateParams, entityType, value)?.then((data: any) => {
        const cc: any = [];
        data.options.forEach((c: { value: string; label: string }) => {
          cc.push({ value: c.value, text: c.label });
        });
        if (!arrayToSet[value]) {
          arrayToSet[value] = cc;
        }
        callback(arrayToSet[value]);
      });
    }
  }

  SurveyCore.Serializer.findClass("itemvalue").findProperty("visibleIf").visible = false;
  SurveyCore.Serializer.findClass("itemvalue").findProperty("value").showMode = "form";
  SurveyCore.Serializer.findClass("itemvalue").findProperty("enableIf").visible = false;

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "trueMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.ITEM_VALUE.TRUE"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      choicesCallback(Preference);
    },
    visibleIf: (obj: any) => {
      if (obj && obj?.getPropertyValue("trueMap") === undefined) {
        obj.setPropertyValue("trueMap", 0);
      }
      return true;
    },
  });

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "falseMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.ITEM_VALUE.FALSE"),
    isSerializable: true,
    showMode: "form",
    isLightSerializable: true,
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      choicesCallback(Preference);
    },
    visibleIf: (obj: any) => {
      if (obj && obj?.getPropertyValue("falseMap") === undefined) {
        obj.setPropertyValue("falseMap", 1);
      }
      return true;
    },
  });

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "contactChoiceMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.ITEM_VALUE.CONTACT_MAP"),
    isSerializable: true,
    showMode: "form",
    dependsOn: ["value"],
    onSetValue: (surveyElement: any, value: any) => {
      surveyElement.setPropertyValue("contactTrueMap", null);
      surveyElement.setPropertyValue("contactFalseMap", null);
      surveyElement.setPropertyValue("contactChoiceMap", value);
    },
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      SetMapperAttributes("boolean", choicesCallback, stateParams, EntityType.Contact, obj);
    },
  });

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "contactTrueMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.CONSENT.TRUE_CONTACT_MAP"),
    isSerializable: true,
    showMode: "form",
    dependsOn: ["contactChoiceMap"],
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      // eslint-disable-next-line no-underscore-dangle
      const selectedAttribute = obj?.contactChoiceMap;
      updateLeadContactOptionsChoices(
        selectedAttribute,
        EntityType.Contact,
        ContactOptionsAttributes,
        choicesCallback,
      );
    },
  });
  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "contactFalseMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.CONSENT.FALSE_CONTACT_MAP"),
    isSerializable: true,
    showMode: "form",
    dependsOn: ["contactChoiceMap"],
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      const selectedAttribute = obj?.contactChoiceMap;
      updateLeadContactOptionsChoices(
        selectedAttribute,
        EntityType.Contact,
        ContactOptionsAttributes,
        choicesCallback,
      );
    },
  });

  if (stateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("itemvalue", {
      name: "leadChoiceMap",
      type: "dropdown",
      displayName: i18next.t("EDITORS_COMMON.ITEM_VALUE.LEAD_MAP"),
      isSerializable: true,
      showMode: "form",
      dependsOn: ["value"],
      onSetValue: (surveyElement: any, value: any) => {
        surveyElement.setPropertyValue("leadTrueMap", null);
        surveyElement.setPropertyValue("leadFalseMap", null);
        surveyElement.setPropertyValue("leadChoiceMap", value);
      },
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        SetMapperAttributes("boolean", choicesCallback, stateParams, EntityType.Lead, obj);
      },
    });

    SurveyCore.Serializer.addProperty("itemvalue", {
      name: "leadTrueMap",
      type: "dropdown",
      displayName: i18next.t("EDITORS_COMMON.CONSENT.TRUE_LEAD_MAP"),
      isSerializable: true,
      showMode: "form",
      dependsOn: ["leadChoiceMap"],
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        const selectedAttribute = obj?.leadChoiceMap;
        updateLeadContactOptionsChoices(
          selectedAttribute,
          EntityType.Lead,
          LeadOptionsAttributes,
          choicesCallback,
        );
      },
    });

    SurveyCore.Serializer.addProperty("itemvalue", {
      name: "leadFalseMap",
      type: "dropdown",
      displayName: i18next.t("EDITORS_COMMON.CONSENT.FALSE_LEAD_MAP"),
      isSerializable: true,
      showMode: "form",
      dependsOn: ["leadChoiceMap"],
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        const selectedAttribute = obj?.leadChoiceMap;
        updateLeadContactOptionsChoices(
          selectedAttribute,
          EntityType.Lead,
          LeadOptionsAttributes,
          choicesCallback,
        );
      },
    });
  }
}
