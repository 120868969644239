import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import GetLeadContactAttributeOptions from "../../common/api/GetLeadContactAttributeOptions";
import { EntityType } from "../../common/enums/EntityType";
import SetItemValueChoicesProperty from "../../common/helpers/SetItemValueChoicesProperty";
import {
  SetContactChoices,
  SetLeadChoices,
} from "../../common/component-collection/ItemValueCommon";
import SetMapperAttributes from "../../common/helpers/SetMapperAttributes";

function RadioGroupToolbox(formStateParams: FormStateParams, dialog: any) {
  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("radiogroup", {
      name: "leadMap",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      isSerializable: true,
      categoryIndex: 8,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        if (surveyElement.isDesignMode) {
          GetLeadContactAttributeOptions(formStateParams, EntityType.Lead, value)?.then(
            (data: any) => {
              SetItemValueChoicesProperty(
                data,
                surveyElement,
                value,
                SetLeadChoices,
                formStateParams,
                ["leadAttributeOptionMap"],
                dialog,
              );
            },
          );
        }

        surveyElement.setPropertyValue("leadMap", value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        SetMapperAttributes("list", choicesCallback, formStateParams, EntityType.Lead, obj);
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("radiogroup", {
    name: "contactMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: true,
    categoryIndex: 8,
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      if (surveyElement.isDesignMode) {
        GetLeadContactAttributeOptions(formStateParams, EntityType.Contact, value)?.then(
          (data: any) => {
            SetItemValueChoicesProperty(
              data,
              surveyElement,
              value,
              SetContactChoices,
              formStateParams,
              ["contactAttributeOptionMap"],
              dialog,
            );
          },
        );
      }

      surveyElement.setPropertyValue("contactMap", value);
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      SetMapperAttributes("list", choicesCallback, formStateParams, EntityType.Contact, obj);
    },
    visibleIndex: 1,
  });

  SurveyCore.Serializer.addProperty("radiogroup", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
  });
}

export default RadioGroupToolbox;
