import { SvgRegistry } from "survey-core";

function Icons() {
  SvgRegistry.registerIconFromSvg(
    "datetimepicker",
    `<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
<path d="M24 24H28V28H24V24ZM36 48H40V52H36V48ZM48 24H52V28H48V24ZM36 24H40V28H36V24ZM24 32H28V36H24V32ZM12 32H16V36H12V32ZM48 32H52V36H48V32ZM36 32H40V36H36V32ZM24 40H28V44H24V40ZM12 40H16V44H12V40ZM48 40H52V44H48V40ZM36 40H40V44H36V40ZM24 48H28V52H24V48ZM12 48H16V52H12V48ZM64 4V60H0V4H12V0H16V4H48V0H52V4H64ZM4 8V16H60V8H52V12H48V8H16V12H12V8H4ZM60 56V20H4V56H60Z" />
</svg>
`,
  );

  SvgRegistry.registerIconFromSvg(
    "country",
    `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9 17.39C17.64 16.59 16.89 16 16 16H15V13C15 12.7348 14.8946 12.4804 14.7071 12.2929C14.5196 12.1054 14.2652 12 14 12H8V10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9V7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5V4.59C16.1965 5.07236 17.2582 5.83747 18.0944 6.81983C18.9306 7.8022 19.5163 8.97255 19.8013 10.2307C20.0864 11.4889 20.0623 12.7974 19.7312 14.0442C19.4001 15.291 18.7717 16.4391 17.9 17.39ZM11 19.93C7.05 19.44 4 16.08 4 12C4 11.38 4.08 10.78 4.21 10.21L9 15V16C9 16.5304 9.21071 17.0391 9.58579 17.4142C9.96086 17.7893 10.4696 18 11 18M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" />
  </svg>
`,
  );
}

export default Icons;
