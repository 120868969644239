import * as SurveyCore from "survey-core";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import { DialogType } from "../enums/DialogType";
import i18next from "../localization/i18n";

async function SetItemValueChoicesProperty(
  data: any,
  surveyElement: any,
  value: any,
  setChoices: any,
  routeParams: FormStateParams | SubscriptionsStateParams,
  propertyNames: string[],
  dialog: any,
) {
  const choices = surveyElement.dataChoices
    ? [...surveyElement.dataChoices]
    : [...surveyElement.choices];
  if ((value === null || value === undefined) && choices?.length === 0) {
    surveyElement.choices = [];
  } else {
    if (surveyElement.survey?.creator?.state === "modified") {
      choices.forEach((choice: any) => {
        propertyNames.forEach((propertyName: string) => {
          choice[propertyName] = null;
        });
      });
    }

    if (data?.options?.length === 0) {
      return;
    }
    const cc: any = [];
    cc.push({ value: null });
    (data as any).options.forEach((c: { value: string; label: string }) => {
      cc.push({ value: c.value, text: c.label });

      choices.push(
        new SurveyCore.ItemValue({
          value: c.value,
          text: c.label,
          ...propertyNames.reduce((a, v) => ({ ...a, [v]: c.value }), {}),
        }),
      );
    });
    setChoices(value, cc);

    if (
      surveyElement.survey?.creator?.state === "modified" &&
      choices?.length > 0 &&
      value !== null &&
      !surveyElement.survey?.isFirstPageRendering
    ) {
      if (
        await dialog(
          DialogType.Confirm,
          i18next.t("NEW_FORM_EDITOR.MAP_SELECTED_OPTIONS_DIALOG.TITLE"),
          i18next.t("NEW_FORM_EDITOR.MAP_SELECTED_OPTIONS_DIALOG.TEXT"),
          i18next.t("NEW_FORM_EDITOR.MAP_SELECTED_OPTIONS_DIALOG.CONTINUE"),
        )
      ) {
        surveyElement.choices = choices;
      }
    }
  }
}

export default SetItemValueChoicesProperty;
