import { RemoveItemFromToolbox } from "../helpers/ToolboxHelper";
import { RegisterPhoneValidators } from "../../common/validators/PhoneValidators";
import SetQuestionNameAndTitle from "../../common/helpers/SetQuestionNameAndTitle";
import i18next from "../../common/localization/i18n";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import Guid from "../../common/helpers/Guid";

export default function OnQuestionAdded(
  sender: any,
  options: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  const { question } = options;
  const questionType = question.getType();
  question.hideNumber = true;

  switch (questionType) {
    case "email_field":
      question.crmFieldType = "email";
      question.titleLocation = "default";
      break;
    case "phone_field":
      question.crmFieldType = "phone";
      question.titleLocation = "default";
      RegisterPhoneValidators(question);
      break;
    case "radiogroup":
      question.crmFieldType = "dropdown";
      question.titleLocation = "default";
      break;
    case "boolean":
      question.crmFieldType = "checkbox";
      question.titleLocation = "default";
      break;
    case "dropdown":
      question.crmFieldType = "dropdown";
      question.titleLocation = "default";
      break;
    case "consent":
      question.crmFieldType = "consent";
      question.titleLocation = "hidden";
      break;
    case "global_unsubscribe_email":
      question.crmFieldType = "global_unsubscribe_email";
      question.titleLocation = "hidden";
      break;
    case "global_unsubscribe_sms":
      question.crmFieldType = "global_unsubscribe_sms";
      question.titleLocation = "hidden";
      break;
    case "checkbox":
      question.titleLocation = "default";
      question.choices = [];
      question.crmFieldType = "subscriptionlist";
      question.subscriptionsType = undefined;
      question.showNoneItem = true;
      question.noneText = i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SUBSCRIPTIONS.UNSUBSCRIBE_FROM_ALL");
      question.showSelectAllItem = true;
      question.selectAllText = i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SUBSCRIPTIONS.SUBSCRIBE_TO_ALL");
      break;
    default:
      question.crmFieldType = questionType;
      question.titleLocation = "default";
  }

  question.clearIfInvisible = "none";
  question.crmId = Guid();

  SetQuestionNameAndTitle(sender, options, "NEW_SUBSCRIPTIONS_EDITOR", questionType);
  RemoveItemFromToolbox(sender, questionType, subscriptionsStateParams);
}
